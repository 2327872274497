<template>
  <div>
    <div style="font-size: 18px">
      页面版本:2023-02-08 15:54
      <div >url: {{this.url}}</div>
    </div>

    <div style="margin: 50px 10px">
      navigateTo:
       <van-field v-model="navigateTo.url" label="url" placeholder="url" />
        <van-field v-model="navigateTo.param" label="param" placeholder="请填写param" />
        <van-button @click="navigateToF" type="primary">执行</van-button>
    </div>
<!--    <div style="margin: 50px 10px">-->
<!--      getScreenHeight:-->
<!--      {{height}}-->
<!--      <van-button @click="getScreenHeight" type="primary">执行</van-button>-->
<!--    </div>-->
    <div style="margin: 50px 10px">
      changeLanguage:
      <van-field v-model="language" label="language" placeholder="请填写language" />
      <van-button @click="changeLanguageF" type="primary">执行</van-button>
    </div>

    <div style="margin: 50px 10px">
      changeDevice:
      <van-field v-model="imei" label="imei" placeholder="请填写imei" />
      <van-button @click="changeDeviceF" type="primary">执行</van-button>
    </div>
    <div style="margin: 50px 10px">
      getDevice:
      <van-button @click="getDeviceF" type="primary">执行1</van-button>
      <van-button @click="getDeviceF2" type="primary">执行2</van-button>
    </div>
    <div style="margin: 50px 10px">
      orderPay:
      <van-field v-model="orderNo" label="orderNo" placeholder="请填写orderNo" />
      <van-button @click="orderPayF" type="primary">执行</van-button>
    </div>
  </div>
</template>

<script>
import {Field,Button,Notify,Dialog} from 'vant';
import {getAppType} from "@/util/common-utils";
export default {
  name: "index",
  components: {
    [Field.name]:Field,
    [Button.name]:Button
  },
  data(){
    return {
      navigateTo:{
        url:"/index",
        param:"{}"
      },
      language:"zh",
      imei:"",
      orderNo:"640f35cae1bf4a7c8c9b728aa532688d",
      height:0
    }
  },
  created() {
    this.url = window.location.href;
    this.appType = getAppType();
  },
  methods:{
    navigateToF(){
      // window.app.navigateTo(this.navigateTo.url,this.navigateTo.param)
      //调用原生注册过的方法
      window.console.info(this.appType)
      try {
        if (this.appType == 1) {
          //IOS
          this.$bridge.callhandler('navigateTo', this.navigateTo, (data) => {
            // 成功通知
            Notify({type: 'success', message: data});
          })
        } else if (this.appType == 2) {
          //Android
          let param = this.navigateTo.param;
          if(param){
            window.console.info("eval ",param)
            param = JSON.parse(param);
          }
          // alert("URL " + this.navigateTo.url)
          // alert("参数 " + JSON.stringify(param))
          window.app.navigateTo(this.navigateTo.url, JSON.stringify(param))
        } else {
          Notify({type: 'danger', message: "未知环境"});
        }
      }catch (e){
        this.errorF(e)
      }

    },
    changeLanguageF(){
      try {
        Notify({type: 'danger', message: navigator.userAgent});
        if(this.appType == 1){
          //IOS
          this.$bridge.callhandler('changeLanguage', this.language, (data) => {
            // 成功通知
            Notify({ type: 'success', message: data });
          })
        }else if(this.appType == 2){
          //Android
          window.app.changeLanguage(this.language)
        }else{
          Notify({ type: 'danger', message: "未知环境" });
        }

      }catch (e){
        this.errorF(e)
      }
    },
    changeDeviceF(){
      try {
        if(this.appType == 1){
          try{
          //IOS
          this.$bridge.callhandler('changeDevice', {imei:this.imei,name:"设备名称",avatar:"http://cdn.youshusoft.com/avatar/user.png"}, (data) => {
            // 成功通知
            Notify({ type: 'success', message: data });
          }) }catch (e) {
          Notify({type: 'danger', message: e.message});
        }
        }else if(this.appType == 2){
          //Android
          window.app.changeDevice(this.imei,"设备名称","http://cdn.youshusoft.com/avatar/user.png")
        }else{
          Notify({ type: 'danger', message: "未知环境" });
        }

      }catch (e){
        this.errorF(e)
      }
    },
    getDeviceF(){
      try {
        if(this.appType == 1){
          //IOS
          this.$bridge.callhandler('getDevice', {}, (data) => {
            // 成功通知
            Notify({ type: 'success', message: data });
          })
        }else if(this.appType == 2){
          //Android
          window.app.getDevice((imei) => {
            Notify({ type: 'success', message: "获取结果 " + imei });
          })
        }else{
          Notify({ type: 'danger', message: "未知环境" });
        }

      }catch (e){
        this.errorF(e)
      }
    },
    getDeviceF2(){
      try {
        if(this.appType == 1){
          //IOS
          this.$bridge.callhandler('getDevice', {}, (data) => {
            // 成功通知
            Notify({ type: 'success', message: data });
          })
        }else if(this.appType == 2){
          //Android
          let imei = window.app.getDevice()
          alert("IMEI:" + imei)
        }else{
          Notify({ type: 'danger', message: "未知环境" });
        }

      }catch (e){
        this.errorF(e)
      }
    },
    orderPayF(){
      try {
        Dialog.confirm({
          title: '支付确认',
          message: '订单支付成功? ' + this.orderNo,
        })
            .then(() => {
              // on confirm
            })
            .catch(() => {
              // on cancel
            });
        if(this.appType == 1){
          //IOS
          this.$bridge.callhandler('orderPay', {orderNo:this.orderNo,payType:1}, () => {
            // // 成功通知
            // Notify({ type: 'success', message: data });
          })
        }else if(this.appType == 2){
          //Android
          window.app.orderPay(this.orderNo,1)
        }else{
          Notify({ type: 'danger', message: "未知环境" });
        }

      }catch (e){
        this.errorF(e)
      }
    },
    errorF(e){
      Notify({ type: 'danger', message: e.message});
      // if(window.console){
      //   window.console.error(e)
      // }
      // alert(e.message)
    },
    getScreenHeight(){
      //调用原生注册过的方法
      this.$bridge.callhandler('getScreenHeight', "调用getScreenHeight方法", (data) => {
        // 处理返回数据
        this.height = data
      })
    }
  }
}
</script>

<style scoped>

</style>
